import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './components/Home/Home';
import NotFound from './components/Errors/NotFound';
import ClubDetails from "./components/ClubDetails/ClubDetails";

function App() {
  return (
    <Router>

      <Switch>

        <Route path="/home">
          <Home />
        </Route>

        <Route path="/club/:clubId">
          <ClubDetails />
        </Route>

        <Route exact path="/">
          <Home />
        </Route>
        
        <Route path="*">
          <NotFound />
        </Route>

      </Switch>

    </Router>
  );
}

export default App;
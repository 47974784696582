import React from 'react';
import './Header.css';
import headerBackground from '../../images/camp-nou.jpg';

const Header = (props) => {
    const showImage = (img) => <img src={img} style={{width: '10%'}} alt="" />;
    return (
        <div className="header d-flex align-items-center justify-content-center" style={{ backgroundImage: `url(${headerBackground})` }}>
            {
                props.teamBadge ? showImage(props.teamBadge) : showImage('https://www.thesportsdb.com/images/media/league/badge/7onmyv1534768460.png')
            }
        </div>
    );
};

export default Header;
import React from 'react';
import './Club.css';
import {
    BrowserRouter as Route,
    useHistory
} from "react-router-dom";

const Club = (props) => {
    const {idTeam, strTeam, strTeamBadge, strSport} = props.club;
    const history = useHistory();

    function handleButtonClick( id ) {
      history.push(`/club/${id}`);
    }

    return (
        <div className="col-md-4">
            <div className="card">
                <div className="card-body d-flex flex-column align-items-center">
                    <div>
                        <img className="rounded mx-auto d-block w-50" src={strTeamBadge} alt=""/>
                    </div>
                    <div className="mt-3">
                        <h3 className="fw-bold">{strTeam}</h3>
                        <p>Sports Type: {strSport}</p>
                    </div>
                    <div>
                        <button onClick={() => handleButtonClick(idTeam)} className="btn btn-primary"> Explore <i className="fas fa-chevron-right"></i> </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Club;
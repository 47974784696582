import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import './ClubDetails.css';
import DefaultBanner from '../../images/default.jpg';
import Header from '../Header/Header';

const ClubDetails = () => {
    const { clubId } = useParams();
    const [ clubData, setClubData ] = useState([]);
    
    useEffect(() => {
        const url = `https://www.thesportsdb.com/api/v1/json/1/lookupteam.php?id=${ clubId }`;
        fetch( url )
        .then( res => res.json() )
        .then( data => setClubData(data.teams[0]) )
    }, [ clubId ]);
    
    const { strTeam, intFormedYear, strTeamBanner, strTeamBadge, strCountry, strGender, strSport, strStadiumDescription, strDescriptionEN, strYoutube, strFacebook, strTwitter, strInstagram, strWebsite } = clubData;
    const showBannerImage = (img) => <img src={ img } className="float-end mx-auto h-100 img-fluid" alt=""/>;

    return (
        <>
        <Header teamBadge={ strTeamBadge }></Header>
        <div className="club-details">
            <div className="container">
                <div className="card">
                    <div className="row">
                        <div className="col-md-6 p-3">
                            <h3>
                                {
                                    strTeam ? strTeam : 'La Liga Team'
                                }
                             </h3>
                            <h5> <i className="fas fa-clock"></i> Founded: { intFormedYear }</h5>
                            <h5> <i className="fas fa-flag"></i> Country: { strCountry }</h5>
                            <h5> <i className="fas fa-futbol"></i> Sports Type: { strSport }</h5>
                            <h5> <i className="fas fa-mars"></i> Gender: { strGender }</h5>
                        </div>
                        <div className="col-md-6">
                            {
                                strTeamBanner ? showBannerImage(strTeamBanner) : showBannerImage(DefaultBanner)
                            }
                        </div>
                    </div>
                </div>
                <p>{ strStadiumDescription }</p>
                <p>{ strDescriptionEN }</p>
                <div className="p-3 text-center sharing">
                    <a target="_blank" rel="noreferrer" href={`https://${ strYoutube }`}><i className="fab fa-youtube"></i></a>
                    <a target="_blank" rel="noreferrer" href={`https://${ strTwitter }`}><i className="fab fa-twitter"></i></a>
                    <a target="_blank" rel="noreferrer" href={`https://${ strFacebook }`}><i className="fab fa-facebook"></i></a>
                    <a target="_blank" rel="noreferrer" href={`https://${ strInstagram }`}><i className="fab fa-instagram"></i></a>
                    <a target="_blank" rel="noreferrer" href={`https://${ strWebsite }`}><i className="fas fa-globe"></i></a>
                </div>
            </div>
        </div>
        </>
    );
};

export default ClubDetails;
import React from 'react';
import Header from '../Header/Header';
import ClubList from '../ClubList/ClubList';
import './Home.css';

const Home = () => {
    return (
        <>
        <Header />
        <div className="club-list">
            <ClubList />
        </div>
        </>
    );
};

export default Home;
import React, { useEffect, useState } from 'react';
import Club from '../Club/Club';

const ClubList = () => {
    const [club, setClub] = useState([]);

    useEffect(() => {
        const url = 'https://www.thesportsdb.com/api/v1/json/1/search_all_teams.php?l=Spanish%20La%20Liga';
        fetch(url)
        .then(res => res.json())
        .then(data => setClub(data.teams))
    }, [])

    return (
        <div className="container">
            <div className="row g-4">
            {
                club.map(club => <Club club={club} key={club.idTeam}></Club>)
            }
            </div>
        </div>
    );
};

export default ClubList;